import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";

// import { hashHistory } from 'react-router;'

// import logo from "assets/img/modeltownlogoshap2.png";
//
import logo from "assets/imgfolder1/modeltownlogoshap2.png";
import './navbar-head.css'

//

import { createBrowserHistory } from "history";
import { Input } from "reactstrap";
import IndexDropdown from "components/Dropdowns/IndexDropdown";

// export const WebNavBar = (props) => {
//   // let history = useHistory();
//   const [navbarOpen, setNavbarOpen] = React.useState(false);

//   const onClick = () => {
//     let data = document.getElementById("example-navbar-warning");
//     if (data.classList.contains("hidden")) {
//       //data.classList.remove('hidden')
//       data.className =
//         "lg:flex items-center bg-white   lg:shadow-none block rounded shadow-lg ";
//     } else {
//       data.className =
//         "lg:flex items-center bg-white  lg:shadow-none block rounded shadow-lg hidden";
//     }
//   };
//   const [isOpen, setIsOpen] = useState(false);

//   const toggle = () => setIsOpen(!isOpen);
//   const links = [
//     {
//       key: 6,

//       path: "/",
//       name: "Home",
//     },
//     // {
//     //   key: 1,

//     //   path: "/aboutus",
//     //   name: "About Us",
//     // },

//     {
//       key: 6,

//       path: "/company",
//       name: "About Us",
//     },
//     {
//       key: 5,

//       path: "/blogs",
//       name: "Blogs",
//     },
//     {
//       key: 8,

//       path: "/facility",
//       name: "Project Facilities",
//     },
//     // {
//     //   key: 9,

//     //   path: "/urfacility",
//     //   name: "Urdu Facilities",
//     // },
//     // {
//     //   key: 7,

//     //   path: "/ur",
//     //   name: "اردو",
//     // },
//     // {
//     //   key: 2,

//     //   path: "/faqs",
//     //   name: "FAQS",
//     // },
//     // {
//     //   key: 4,

//     //   path: "/web/contact",
//     //   name: "Blogs",
//     // },
//     // {
//     //   key: 5,

//     //   path: "/auth/login",
//     //   name: "Sign in",
//     // },
//   ];
//   const redirect = (path) => {
//     // hashHistory.push('/');
//   };
//   const closeBar = () => {
//     setNavbarOpen(false);
//   };
//   return (
//     // <>
//     //   <nav className="top-0 nav-sticky absolute z-50 w-full flex flex-wrap items-center justify-between  navbar-expand-lg bg-transparent" style={{ padding: '10px' }}>
//     //     <div className="container px-4 mx-auto flex flex-wrap items-center justify-between mgT--20px">
//     //       <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
//     //         <Link
//     //           to="/"
//     //           className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
//     //         >
//     //           {/* NOTUS REACT */}
//     //           <div>
//     //             <img src={logo} width="100px" />
//     //           </div>
//     //         </Link>
//     //         <button
//     //           className="cursor-pointer text-xl leading-none px-3 py-1 block lg:hidden outline-none focus:outline-none"
//     //           type="button"
//     //           onClick={() => setNavbarOpen(!navbarOpen)}
//     //         >
//     //           <i className="fas fa-bars text-white"></i>
//     //         </button>
//     //       </div>
//     //       <div
//     //         style={{ paddingLeft: "15px" }}
//     //         className={
//     //           "lg:flex flex-grow items-center bg-sqblue rounded-t-lg lg:bg-transparent lg:shadow-none" +
//     //           (navbarOpen ? " block bg-kgcbrown" : " hidden")
//     //         }
//     //         id="example-navbar-warning"
//     //       >

//     //         <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
//     //           {links !== undefined && links !== '' && links.map((link, i) => {
//     //             return (
//     //               <div key={i}>
//     //               <li key={link.key} className="flex items-center text-kgcbrown " onClick={redirect(link.path)}>
//     //                 <Link
//     //                   to={link.path}
//     //                   className="text-white font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
//     //                   onClick={closeBar}
//     //                 >
//     //                   {link.name}
//     //                 </Link>
//     //               </li>
//     //               </div>
//     //             );
//     //           })}
//     //           <li>
//     //       <div className="flex items-center text-kgcbrown">
//     //         <Input className="bg-kgcbrown" type="select" name="language">

//     //           <option>English</option>
//     //           <option>Urdu</option>

//     //         </Input>
//     //       </div>
//     //     </li>
//     //         </ul>
//     //       </div>
//     //     </div>
//     //   </nav>
//     // </>

//   );
// };

//

const NavbarHead = (props) => {
    // let history = useHistory();
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [navclass, setnavclass] = useState("navbar");
    const changeNavBg = () => {
        window.scrollY > 0 ? setnavclass("navbarfixed") : setnavclass("navbar");
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNavBg);
        return () => {
            window.removeEventListener("scroll", changeNavBg);
        };
    }, []);
    const onClick = () => {
        let data = document.getElementById("example-navbar-warning");
        if (data.classList.contains("hidden")) {
            //data.classList.remove('hidden')
            data.className =
                "lg:flex items-center bg-white   lg:shadow-none block rounded shadow-lg ";
        } else {
            data.className =
                "lg:flex items-center bg-white  lg:shadow-none block rounded shadow-lg hidden";
        }
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const links = [
        {
            key: 6,

            path: "#home",
            name: "Home",
        },
        // {
        //   key: 1,

        //   path: "/aboutus",
        //   name: "About Us",
        // },

        {
            key: 6,

            path: "#about",
            name: "About Us",
        },
        {
            key: 8,

            path: "#facilities",
            name: "Facilities",
        },
        {
            key: 8,

            path: "#promo",
            name: "Promo",
        },
        {
            key: 5,

            path: "#amenities",
            name: "AMENITIES",
        }, {
            key: 5,

            path: "#contact",
            name: "Contact",
        },
        // {
        //   key: 5,

        //   path: "#Contact Us",
        //   name: "Contact Us",
        // },
        // {
        //   key: 9,

        //   path: "/urfacility",
        //   name: "Urdu Facilities",
        // },
        // {
        //   key: 7,

        //   path: "/ur",
        //   name: "اردو",
        // },
        // {
        //   key: 2,

        //   path: "/faqs",
        //   name: "FAQS",
        // },
        // {
        //   key: 4,

        //   path: "/web/contact",
        //   name: "Blogs",
        // },
        // {
        //   key: 5,

        //   path: "/auth/login",
        //   name: "Sign in",
        // },
    ];
    const redirect = (path) => {
        // hashHistory.push('/');
    };
    const closeBar = () => {
        setNavbarOpen(false);
    };

    // clean up code

    return (
        <>
            <nav
                style={{ padding: "0px" }}
                className={`top-0  absolute z-50  w-full flex flex-wrap items-center justify-between navbar-expand-lg ${navclass}`}
            >
                <div
                    className="container flex flex-wrap items-center justify-between "
                    style={{
                        marginTop: "",
                        marginTop: "0",
                        padding: "0",
                        position: "relative",
                    }}
                >
                    <div
                        className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
                        style={{
                            marginTop: "",
                            height: "90px",
                            overflow: navclass == "navbarfixed" ? "hidden" : null,
                        }}
                    >
                        <div
                            style={{
                                overflow:
                                    navclass == "navbarfixed"
                                        ? navbarOpen
                                            ? "block"
                                            : "hidden"
                                        : null,
                            }}
                            to="/"
                            className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
                        >
                            {/* NOTUS REACT */}
                            <div
                                className="navLogoDiv"
                                style={{
                                    width: navclass == "navbar" ? "130px" : "80px",
                                    position: "absolute",
                                    marginTop: "-20px",
                                    maxHeight: "80px",
                                    overflow:
                                        navclass == "navbarfixed"
                                            ? navbarOpen
                                                ? "block"
                                                : "hidden"
                                            : null,
                                    top: navclass == "navbar" ? "0%" : "0%",
                                }}
                            >
                                <img style={{ height: "" }} src={logo} className="navlogo" />
                            </div>
                        </div>

                        <button
                            className="cursor-pointer text-xl leading-none px-3 py-1 block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <i
                                className={navbarOpen ? "fas fa-times" : "fas fa-bars"}
                                style={{ color: navclass == "navbarfixed" ? "black" : "white" }}
                            ></i>
                        </button>
                    </div>
                    <div
                        style={{
                            paddingLeft: "15px",
                            justifyContent: "center",
                            // alignItems: "center",
                            margin: "0 10px",
                        }}
                        className={
                            "lg:flex flex-grow items-center bg-sqblue rounded-t-lg lg:bg-transparent lg:shadow-none" +
                            (navbarOpen ? " block bg-kgcred" : " hidden")
                        }
                        id="example-navbar-warning"
                    >
                        <ul
                            className="flex flex-col lg:flex-row list-none lg:ml-auto "
                            style={{ alignItems: "center", padding: 0, margin: 0 }}
                        >
                            {links !== undefined &&
                                links !== "" &&
                                links.map((link, i) => {
                                    return (
                                        <div key={i}>
                                            <li
                                                key={link.key}
                                                // className="flex items-center text-kgcred "
                                                onClick={redirect(link.path)}
                                            >
                                                <a
                                                    // style={{
                                                    //   color: navbarOpen
                                                    //     ? navclass == "navbar"
                                                    //       ? "black"
                                                    //       : "white"
                                                    //     : null,
                                                    // }}
                                                    href={link.path}
                                                    className=" font-bold leading-relaxed inline-block  whitespace-no-wrap mr-4 py-2 uppercase text-nav-a-hover navLinks"
                                                    onClick={closeBar}
                                                >
                                                    {link.name}
                                                </a>
                                            </li>
                                        </div>
                                    );
                                })}
                            {/* <li>
          <div className="flex items-center text-kgcbrown">
            <Input className="bg-kgcbrown" type="select" name="language">

              <option>English</option>
              <option>Urdu</option>

            </Input>
          </div>
        </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};
export default NavbarHead
