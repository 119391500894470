import React, { Component, useState } from "react";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
// import { IntroVideo } from "components/IntroVideo";
import background from "assets/img/1.2.jpg";
import Navbar from "components/Navbars/AuthNavbar";
import { WebNavBar } from "components/Navbars/WebNavBar";

// import bione from "assets/imgfolder1/1.1.jpg";
// import bitwo from "assets/imgfolder1/day.jpg";
// import bithreee from "assets/imgfolder1/night.jpg";


import bione from "assets/facilities/cctv.jpg";
import bitwo from "assets/facilities/electricity.jpg";
import bithreee from "assets/facilities/waste.jpg";
import bifour from "assets/facilities/road.jpg";
import bifive from "assets/facilities/natural.jpg";
import bisix from "assets/facilities/modern.jpg";

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: 0,
      data: [
        {
          key: 1,
          title:
            "CCTV",
          img: bione,
          description:"Our security teams duties are primarily to secure and protect the residents of society."
        },
        {
          key: 2,
          title:
            "Electricity Power",
          img: bitwo,
          description:"Providing Electricity advantages that can benefit your family and Society Road Networks."
        },
        {
          key: 3,
          title:
            "Waste Management",
          img: bithreee,
          description:"Includes daily routine collection, transportation, processing, and disposal."
        },
        {
          key: 4,
          title: "Modern Infrastructure",
          img: bisix,
          description:"Social & Economic Infrastructure provides Schools, Wide-Roads, and Ready-Made Houses."
        },
        {
          key: 5,
          title: "Wide Road Network",
          img: bifour,
          description:"A system of interconnected paved carriageways designed to carry buses, cars, and goods vehicles."
        },
        {
          key: 6,
          title: "Natural Gas",
          img: bifive,
          description:"Because it burns smoother, it is the most environmentally beneficial fossil fuel."
        },
      ],
    };
  }

  toggle(e) {
    let event = e.target.dataset.event;
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event),
    });
  }

  componentDidMount() {
  }
  render() {
    const { cards, collapse } = this.state;
    return (
      <>
        {/* <WebNavBar /> */}
        {/* <main> */}
        {/* <div className="relative  pb-32 flex content-center items-center justify-center min-h1-screen-75 ">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: "url(" + background + ")",
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-75 bg-blue"
              ></span>
            </div>

            <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-kgcbrown fill-current"
                  points="2560 50 2560 100 0 100"
                ></polygon>
              </svg>
            </div>
          </div> */}
        {/* <section className="pb-20 relative block bg-white "> */}
        {/* <div className="container mx-auto pt-5 px-4">
              <div class="w-full md:w-3/5 mx-auto p-8">
                <h3 className="page-header text-sqblue text-4xl pb-2 font-bold">
                  FAQS
                </h3> */}
        {this.state.data !== null &&
          this.state.data !== undefined &&
          this.state.data.map((record) => {
            return (
              <Card style={{ marginBottom: "1rem" }} key={record.key}>
                <CardHeader
                  className="text-base rounded font-bold text-sqblue" style={{background:"#9f1c33",color:'white'}} 
                  onClick={this.toggle}
                  data-event={record.key}
                >
                 {record.title}
                </CardHeader>
                <Collapse isOpen={collapse === record.key}>
                  <CardBody className="text-sqblue ">
                    {record.description}
                    <div style={{border:"",height:"220px",width:"100%",marginTop:"2%"}}>
                      <img src={record.img} style={{border:"",height:"220px",width:"100%"}} />
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            );
          })}
        {/* {cards.map(index => {
              return (
            
              )
            })}      */}
        {/* </div>
            </div> */}
        {/* </section> */}
        {/* </main>  */}
      </>
    );
  }
}

export default Faqs;
