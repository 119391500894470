import React from "react"
import "../styleareeb.css"
import { Row, Col, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io";
import { VscCallOutgoing } from "react-icons/vsc";
import { SlEnvolope } from "react-icons/sl";


const ContactForm = () => {
    const { t, i18n } = useTranslation()

    const formsubmit = (event) => {
        event.preventDefault()
        // alert("Working")
    }
    return (
        <>
            <Row style={{ marginLeft: "2%", marginRight: "2%", marginTop: "-4%", marginBottom: "" }} >
                <Col className="flex flex-wrap justify-center text-center mt-5">
                    <h1
                        className="myHeading"
                        style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                        <span className="text-kgcred">{t("contact")}</span> {t("Us")}
                    </h1>
                </Col>
            </Row>
            <div
                style={{ marginLeft: "10%", marginRight: "10%" }}
                className="hr-theme-slash-2"
            >
                <div className="hr-line"></div>
                <div className="hr-icon">
                    <AiFillInfoCircle color="#9f1c33" size={20} />
                </div>
                <div className="hr-line"></div>
            </div>
            <div className="row1" style={{ marginLeft: "1%", marginRight: "1%", marginTop: "2%", marginBottom: "5%", border: "" }} data-aos="fade-right">
                <div className="column1" style={{ backgroundColor: '#2d2d2d' }}>
                    <div style={{ padding: "6px", width: "90%", margin: "0 auto", border: "" }}>
                        <h1 style={{ fontWeight: "bold" }} className="text-kgcred" >Get in Touch With us!</h1>
                        <p className="" style={{ color: "#fff", fontWeight: "500" }}>Fill out the form below & we got back to you as soon as possible</p>
                        <div style={{ padding: "2px", marginBottom: "" }}>
                            <form>
                                <input className="input1" placeholder="Your Name *" type="text" />&nbsp;
                                <input className="input1" placeholder="Email *" type="email" />&nbsp;
                                <input className="input1" placeholder="Phone *" type="phone" />&nbsp;
                                <input className="input1" placeholder="Subject *" type="text" />&nbsp;
                                {/* <select className="input1" name="cars" id="cars">&nbsp;
                                    <option value="volvo">Select Source</option>
                                    <option value="saab">Saab</option>
                                    <option value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                </select>&nbsp;
                                <select style={{ backgroundColor: '#2d2d2d' }} className="input1" name="cars" id="cars">&nbsp;

                                    <option style={{ backgroundColor: '#2d2d2d' }} value="Project">Select Project</option>
                                    <option style={{ backgroundColor: '#2d2d2d' }} value="saab">Saab</option>
                                    <option style={{ backgroundColor: '#2d2d2d' }} value="opel">Opel</option>
                                    <option value="audi">Audi</option>
                                </select> */}
                                <textarea className="input1" style={{ width: "99%" }} placeholder="Message *" rows="3" cols="20" name="comment" type="message" />
                                <button className="button1" onClick={(event) => formsubmit(event)}>Sumbit</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="column1" style={{ backgroundColor: '', }}>
                    <div style={{ padding: "", width: "90%", margin: "0 auto", border: "" }}>
                        <h1 className="ourContact" style={{ fontWeight: "bold", marginLeft: "", textAlign: "center" }}>Our Contact Details</h1>
                        {/* <p><strong>Resize the browser window to see the responsive effect.</strong></p> */}
                        <div className="row-in" style={{ backgroundColor: '', border: "", marginTop: '' }}>
                            <div className="column-in  " style={{ backgroundColor: '', textAlign: "center" }}>
                                {/* <i className="fa fa-phone fa-2x text-kgcred " aria-hidden="true"></i> */}
                                {/* <BsWhatsapp color="#9f1c33" size={20} />  */}
                                <div style={{ border: "", display: "flex", justifyContent: "center" }}>
                                    <VscCallOutgoing color="#9f1c33" size={33} />
                                </div>
                                <div style={{ fontWeight: "" }}>
                                    <div className="size-text" >UAN No</div><a href="tel:+923041110685" target="_blank" style={{ color: "black" }}>0304-1110-685</a>  <div></div>
                                </div>
                                {/* <a
                                    className="fa fa-phone fa-3x text-kgcred text-hover-a"
                                    href=""
                                    target="_blank"
                                > <div className="size-text" style={{ fontWeight: "", marginTop: "3%" }}>
                                        Tell Free <div>081221221</div>
                                    </div></a> */}
                            </div>
                            <div className="column-in " style={{ backgroundColor: '', textAlign: "center" }}>
                                <i className="fab fa-whatsapp fa-2x text-kgcred " aria-hidden="true"></i>
                                <div style={{ fontWeight: "" }}>
                                    <div className="size-text">Whatsapp</div><div> <a href="https://wa.link/wrhcac" target="_blank" style={{ color: "black" }}>+92327-2145-903</a></div>
                                </div>
                                {/* <a
                                    className="fab fa-whatsapp fa-3x text-kgcred text-hover-a"
                                    href=""
                                    target="_blank"
                                > <div className="size-text" style={{ fontWeight: "", marginTop: "3%" }}>
                                        Whatsapp <div>+9234432423</div>
                                    </div></a> */}
                            </div>
                            <div className="column-in  " style={{ backgroundColor: '', textAlign: "center" }}>
                                {/* <h2>Column 1</h2> */}
                                {/* <i className="fa fa-envelope fa-2x text-kgcred " ></i> */}
                                <div style={{ border: "", display: "flex", justifyContent: "center" }}>
                                    <SlEnvolope color="#9f1c33" size={33} />
                                </div>
                                <div style={{ fontWeight: "" }}>
                                    <div className="size-text">Email</div> <a href="mailto:[modeltownm9.com.pk]" target="_blank" style={{ color: "black" }}><div>info@modeltownm9.com.pk</div></a>
                                </div>
                                {/* <a
                                    className="fa fa-envelope fa-3x text-kgcred text-hover-a"
                                    href=""
                                    target="_blank"
                                > <div className="size-text" style={{ fontWeight: "", marginTop: "3%" }}>
                                        Email <div>emomodel@gmail.com</div>
                                    </div></a> */}
                            </div>
                            <div className="column-in " style={{ backgroundColor: '', textAlign: "center" }}>
                                {/* <i className="fa fa-map-marker fa-3x text-kgcred " aria-hidden="true"></i> */}
                                {/* <i className="fa fa-location-arrow fa-2x " aria-hidden="true"></i> */}
                                <div style={{display:"flex",justifyContent:"center"}}>
                                    <svg stroke="currentColor" fill="" className="text-kgcred" strokeWidth="0" viewBox="0 0 512 512" height="40" width="" xmlns="http://www.w3.org/2000/svg" ><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"></path><circle cx="256" cy="192" r="48" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"></circle></svg>
                                </div>

                                {/* <a
                                    className="fa fa-map-marker fa-3x text-kgcred text-hover-a"
                                    href="https://www.google.com/maps/place/Dashtyar+Pride%D8%8C+plot+F-1+Block+13-D%D8%8C+Plot+F-1+Block+13-D,+Block+13+A+Gulshan-e-Iqbal,+Karachi,+Karachi+City,+Sindh,+Pakistan%E2%80%AD/@24.9036075,67.0680862,17z/data=!3m1!4b1!4m5!3m4!1s0x3eb33f204f723173:0x11f8a785854c26b0!8m2!3d24.9036027!4d67.0723134"
                                    target="_blank"
                                > <div className="size-text" style={{ fontWeight: "", marginTop: "3%" }}>
                                        Address <div>Office #10,Dashtyar Pride,13-D,Gulshan-e-Iqbal,Near Hassan Square,Karachi.</div>
                                    </div></a> */}
                                <div style={{ fontWeight: "" }}>
                                    <div className="size-text">Address</div><a target="_blank" style={{ color: "black" }} href="https://www.google.com/maps/place/Dashtyar+Pride%D8%8C+plot+F-1+Block+13-D%D8%8C+Plot+F-1+Block+13-D,+Block+13+A+Gulshan-e-Iqbal,+Karachi,+Karachi+City,+Sindh,+Pakistan%E2%80%AD/@24.9036075,67.0680862,17z/data=!3m1!4b1!4m5!3m4!1s0x3eb33f204f723173:0x11f8a785854c26b0!8m2!3d24.9036027!4d67.0723134"><div> Office #10,Dashtyar Pride,13-D,Gulshan-e-Iqbal,Near Hassan Square,Karachi.</div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export { ContactForm }