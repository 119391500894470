import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

// components
import { Parallax, Background } from "react-parallax";

import { useTranslation } from "react-i18next";
import Navbar from "components/Navbars/AuthNavbar.js";
import BackgroundSlider from "react-background-slider";
import bi1 from "assets/img/1.1.jpg";
import bi2 from "assets/img/1.2.jpg";
import bi3 from "assets/img/1.3.jpg";

//
import bioneMob from "assets/imgfolder1/New folder/1.jpg";
import bitwoMob from "assets/imgfolder1/New folder/day.jpg";
import bithreeeMob from "assets/imgfolder1/New folder/night.jpg";

import bione from "assets/imgfolder1/1.1.jpg";
import bitwo from "assets/imgfolder1/day.jpg";
import bithreee from "assets/imgfolder1/night.jpg";
//
// import logo from "assets/img/khlogo-dark.png";
import p1 from "assets/img/merger.png";
import { Row, Col, Button, Container, UncontrolledCarousel } from "reactstrap";
import { About } from "./HomePageSection/AboutUs";
import { Features } from "./HomePageSection/Features";
import { Portal } from "./HomePageSection/Portal";
import { Video } from "./HomePageSection/Video";
import { Work } from "./HomePageSection/Work";
import LanguageRadio from "components/language/language_radio";
import Demo from "components/AnimatedText/animatedtext";
import { Demo1 } from "components/AnimatedText/animatedtext2";
import image1 from "assets/imgfolder1/ProjectPromo.jpg";
import image2 from "assets/imgfolder1/playimage2.jpg";
import { AiFillInfoCircle } from "react-icons/ai";

import { Countdown } from "components/Timer/timer";
import BookingForm from "../views/HomePageSection/booking-form.js";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Flip3,
  Flip4,
  Flip5,
  Flip6,
  Flip7,
  Flip8,
} from "components/FlipCard/flipcard3";
import {
  FlipCard1,
  FlipCard2,
  FlipCard3,
  FlipCard4,
  FlipCard5,
  FlipCard6,
} from "components/FlipCard/flipcard";
import { AnimatedText } from "components/AnimatedText/animatedtext";
import { CustomChat } from "components/chat/CustomChat";
import { WebNavBar } from "components/Navbars/WebNavBar";
import CountdownTimer from "react-component-countdown-timer";
import BuilderDemo from "./builder-change";
import moment from "moment";
import { Facility } from "./HomePageSection/Facility";
import FooterV from "components/Footers/Footer-v2";
import Footer from "components/Footers/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Faqs from "./FAQs";
import { Contact } from "./Contact/contact";
import Dashboard from "./admin/Dashboard";
// import NavbarHead from "components/Navbars/navbar-head";
import { ContactForm } from "./HomePageSection/contact-form";

import '../components/Navbars/navbar-head.css'
import './styleareeb.css'
import NavbarHead from "components/Navbars/navbar-head";
import  Carsoul from "./carsoul";
const styles = {
  fontFamily: "Poppins",
  textAlign: "center",
};
const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};
const ShowTime = () => {
  let date = Date.now;
  console.log(date);
};
export default function Landing() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      {/* <Navbar transparent /> */}
      {/* <NavbarHead/> */}
      <div className="mobile-show-navbar" style={{ display: "none" }}>
        <NavbarHead />
      </div>
      <div className="computer-show-navbar">
        <WebNavBar />
      </div>

      <main style={{}} className="main1">
        <div
          className="relative pt-16 pb-32 flex conten  t-center items-center justify-center min-h-screen-75 "
          id="home"
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{ objectFit: "cover" }}
          >
            {/*             
            <BackgroundSlider
              className=""
              // images={[bi1, bi2, bi3]}
              images={[bione, bitwo, bithreee]}
              duration={2}
              transition={1}
            /> */}
            <div className="mobileSc">
              <Carsoul />
              {/* <BackgroundSlider
                className=""
                // images={[bi1, bi2, bi3]}
                images={[ bitwo, bithreee]}
                duration={2}
                  transition={1}
              /> */}
              <div id="abouts"></div>
            </div>
            <div className="computerSc">
              <div style={{ border: "" }}>
                {/* <BackgroundSlider
                  images={[ bitwoMob, bithreeeMob]}
                  duration={2}
                  transition={1}
                // style={{ width: "400", height: "300" }}
                /> */}
                 <Carousel controls={false}>
                <Carousel.Item  interval={1000}>
                    <img
                        className="d-block w-100 " style={{ height: "100vh" }}
                        src={bitwoMob}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item >
                    <img
                        className="d-block w-100" style={{ height: "100vh" }}
                        src={bithreeeMob}
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                
            </Carousel>
              </div>
              <div id="abouts"></div>
            </div>
            {/* <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span> */}
          </div>
          {/* 
          <div className="container relative mx-auto ">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center"> */}
          {/* <div className="mt-24"> */}
          {/* <h1 className="text-white font-bold">
                    <Demo t={t} />
                  </h1>
                  <p className="mt-2 text-3xl text-white"></p>
                  <button
                  className="bg-kgcred text-white active:bg-red text-base font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded"
                  type="button"
                  >
                  <a
                      // onClick={ShowTime}
                      // target="_blank"
                      className="text-white"
                      >
                      {t("signUpButtonText")}
                    </a>
                  </button> */}

          {/* <CountdownTimer count={5432}  showTitle noPoints   />
                  
                  
                  <Countdown
                  timeTillDate="02 10 2021, 00:00 a"
                  timeFormat="MM DD YYYY, h:mm a"
                /> */}
          {/* </div> */}
          {/* </div>
            </div>
          </div> */}
        </div>
        {/* 
        <section className=" bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            <Container>
            <Row>
                  <Col className="flex flex-wrap justify-center text-center mt-5">
                    <img src={p1} />
                    </Col>
                </Row>
                
                <Row>
              <Col className="flex flex-wrap justify-center text-center mb-0"> */}
        {/* <h4
                      className="h4"
                      style={{
                        fontStyle: "",
                        color: "#A0843A",
                        fontWeight: "bolder",
                        textTransform: "capitalize",
                      }}
                    >
                    A Project of Sehwan Development Authority (SDA) under
                    sponsorship of{" "}
                    <a
                        href="https://kgcp.com.pk"
                        target="_blank"
                        className="text-kgcbrown"
                        >
                        KGC Properties
                        </a>
                    </h4> */}
        {/* <Demo1 t={t} />
                  </Col>
                  </Row>
                  </Container>
                  </div>
                  
                  <div className="flex flex-wrap items-center mt-16"></div>
                  </div>
                </section> */}

        <section className="relative py-20" >
          {/* svg line */}
          {/* <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
            >
            <svg
              className="absolute bottom-0 overflow-hidden"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-kgcred fill-current"
                points="2560 10 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}

          {/* <BuilderDemo/> */}
          {/* <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap"> */}
          <About />
          {/* <Facility/> */}

          {/* <CustomChat/> */}
          {/* </div>
          </div> */}
              <div id="facilities"></div>
        </section>

        <section className="relative py-20">
          {/* svg line */}
          {/* <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)", border: "" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-kgcred fill-current"
                points="2560 10 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}

          <div className="px-4">
            <div className="items-center flex flex-wrap" >
              <Portal />
              {/* <Facility/> */}

              {/* <CustomChat/> */}
            </div>
          </div>
         <div id='promo'></div> 
        </section>

        <div data-aos="fade-up" style={{ border: '', marginRight: '2%', marginLeft: "2%", marginTop: "-2%" }} >
          {/* <h1 style={{ padding: "10px" }}>Building Digital Products, Brand & Experiwnce</h1> */}
          <Row style={{ marginLeft: "2%", marginRight: "2%", marginTop: "", marginBottom: "" }} >
            <Col className="flex flex-wrap justify-center text-center mt-5">
              <h1
                className="myHeading"
                style={{ fontWeight: "bold", textTransform: "uppercase" }}
              >
                <span className="text-kgcred">WATCH PROJECT
                  {/* {t("contact")} */}
                </span> PROMO VIDEO
                {/* {t("Us")} */}
              </h1>
            </Col>
          </Row>
          <div
            style={{ marginLeft: "10%", marginRight: "10%" }}
            className="hr-theme-slash-2"
          >
            <div className="hr-line"></div>
            <div className="hr-icon">
              <AiFillInfoCircle color="#9f1c33" size={20} />
            </div>
            <div className="hr-line"></div>
          </div>
          <div className="row-promo">
            <div className="column-promo" style={{ backgroundColor: '', zIndex: "" }}>
              <div className="container-set-img-btn">
                <img className="img1" src={image1} style={{ height: "400px" }} alt="" />
                {/* <div className="btn-img"> */}
                <Video />
                {/* </div> */}
              </div>
              {/* <section className="pb-20 relative block " id="promo" style={{ border: "" }}>
                <div className=" mx-auto px-4 lg:pt-24 lg:pb-64" style={{ border: "2px solid green" }}>
                  <div className="flex flex-wrap text-center justify-center">
                    <div className="w-full lg:w-6/12 px-4">
                      <div
                        className="text-lg leading-relaxed margintop-4 mb-4 text-gray-500"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                <Video />

                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
            </div>
            <div className="column-promo" style={{ backgroundColor: '', zIndex: "-1", alignItems: "", border: "" }}>
              <div className="modern-gated-pad" style={{ paddingTop: "", fontSize: "1.6rem", paddingLeft: "" }}>Model Town M9 is a modern gated community that offers its residents access to all the luxuries and amenities of life. Located in a prime location, it has everything you need to live in comfort and style.</div>
              <a href="#contact"> <button style={{ marginTop: "20px", paddingLeft: "", marginLeft: "" }} className="button-59" role="button">Contact us</button></a>
            </div>
          </div>
        </div>
        {/* <Container> */}
        {/* <Parallax
          bgImage={window.innerWidth <= 550 ? image2 : image1}
          strength={320}
        >
          <section className="pb-20 relative block" id="promo" >
            <div
              className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
              style={{ transform: "translateZ(0)" }}
            >
              <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="text-kgcred fill-current"
                  points="2560 30 2560 100 0 100"
                ></polygon>
              </svg>
            </div>

            <div className="container mx-auto px-4 lg:pt-24 lg:pb-64" data-aos="flip-up" style={{ border: "2px solid green" }}>
              <div className="flex flex-wrap text-center justify-center">
                <div className="w-full lg:w-6/12 px-4">
                  <Col className="flex flex-wrap justify-center text-center mt-5">
                    <h1
                        className="myHeading"
                        style={{ fontWeight: "bold", textTransform: "uppercase" }}
                      >
                        <span className="text-kgcred">{t("project")}</span>
                        <span style={{ color: "white" }}>{t("promo")}</span>
                      </h1>
                  </Col>
                  <div
                    className="text-lg leading-relaxed margintop-4 mb-4 text-gray-500"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}

                  >
                    <Video />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Parallax> */}
        {/* </Container> */}
        {/* <section className="pb-20 relative block bg-white">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-kgcbrown fill-current"
                points="2560 30 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-60">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <Col className="flex flex-wrap justify-center text-center mt-5">
                  <h1
                    className="h1"
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                  >
                    <span style={{ color: "#A0843A" }}>OUR</span> FACILITIES
                  </h1>
                </Col>
                <h3 className="text-lg leading-relaxed mt-4 mb-4 text-black uppercase h3">
                  Facilities Available
                </h3>
              </div>
            </div>

            <div className="flex flex-wrap mt-12 justify-center">
              <div className=" lg:w-4/12 px-4 text-center pb-1 ">
                <Flip3 />
              </div>
              <div className=" lg:w-4/12 px-4 text-center pb-1">
                <Flip4 />
              </div>
              <div className=" lg:w-4/12 px-4 text-center ">
                <Flip5 />
              </div>
              <div className=" lg:w-4/12 px-4 text-center ">
                <Flip6 />
              </div>
              <div className=" lg:w-4/12 px-4 text-center ">
                <Flip7 />
              </div>
              <div className=" lg:w-4/12 px-4 text-center ">
                <Flip8 />
              </div>
            </div>
          </div>
        </section> */}
        <section className="pb-20 relative block bg-gray-300" id="amenities">
          {/* svg line */}
          {/* <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
            id="amenities"
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-kgcred fill-current"
                points="2560 30 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}
        </section>
        <Work />
        <div id='contact' style={{ marginBottom: "110px" }}>

        </div>
        <section className="pb-20 relative block bg-gray-300" >
          {/* svg line */}
          {/* <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
            id="amenities"
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-kgcred fill-current"
                points="2560 30 2560 100 0 100"
              ></polygon>
            </svg>
          </div> */}
        </section>
        <ContactForm />

        {/* <br/> */}
        {/* <Contact/> */}
        {/* <BookingForm /> */}
        {/* <Faqs /> */}
        {/* <Facility /> */}
        {/* <Contact/> */}
        {/* <Dashboard/> */}

        {/* <ApartmentV2/> */}



        <div className="mobileSc">
          <FooterV />
        </div>
        <div className="computerSc">
          <Footer />
        </div>
      </main>
    </>
  );
}
