import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";

import SideImage from "assets/imgfolder1/aboutsideimg.jpg";
import AboutPotraitOne from "assets/imgfolder1/about-potrait-1.png";
// import AboutPotraittwo from "assets/imgfolder1/about-potrait-2.png";
import checkOne from "assets/imgfolder1/temp.jpg";
import AliceCarousel from "react-alice-carousel";
import p1 from "assets/img/merger.png";
import img1 from "assets/img/cee.png";
import img2 from "assets/img/easypaisa.png";
import img3 from "assets/img/foree.png";
import img4 from "assets/img/jazz.png";
import img5 from "assets/img/omni.png";
import img6 from "assets/img/ubank.png";
import img7 from "assets/img/ubl.png";
import img8 from "assets/img/ABL.png";
import { useTranslation } from "react-i18next";
import sfex from "assets/img/sfexlogo.png";
import { strings } from '../../i18n';
import Footer from "components/Footers/Footer";
import axios from "axios";
import ModalVideo from 'react-modal-video'
import { AiFillInfoCircle } from "react-icons/ai"
import "../styleareeb.css"

export const About = () => {
  const { t, i18n } = useTranslation();
  const [video, setVideo] = useState(null);
  const responsive = {
    0: { items: 3 },
    568: { items: 4 },
    1024: { items: 5 },
  };
  useEffect(() => {
    FetchVideo();
  }, [true])
  useEffect(() => {
    console.log('Video', video);
  }, [video])
  const items = [
    <img src={img1} />,
    <img src={img2} />,
    <img src={img3} />,
    <img src={img5} />,
    <img src={img6} />,
    <img src={img7} />,
    <img src={img8} />,
  ];


  const VideoItem = [
    <img src={video !== null ? video[0].snippet.thumbnails.high : ''} />,
    <img src={img1} />,
    //     <ModalVideo channel='youtube' autoplay 
    //     //isOpen={isOpen} 
    //     videoId='AdboEnX-dxs' 
    //     //onClose={() => setOpen(false)} 
    //     />,
    // <ModalVideo channel='youtube' autoplay 
    //     //isOpen={isOpen} 
    //     videoId='AdboEnX-dxs' 
    //     //onClose={() => setOpen(false)} 
    //     />,
  ]




  const FetchVideo = () => {
    axios.get('https://www.googleapis.com/youtube/v3/search?key=AIzaSyCRYjJTT2AqdlWYYx9WT3dDlr8JjPymXIE&channelId=UCMyHcGXbKge5bLKHOc12rMg&part=snippet,id&order=date&maxResults=20')
      .then(res => setVideo(res.data.items))

  }
  const data = [
    {
      key: 1,
      data:
        "Karachi hills (with SDA) aims to serve the needs of Pakistani community in housing and related services. We strive to be a world-class housing solution provider and innovator with leadership in quality, value for money and management. We put customer, quality, talent and prudence first as the core values that support our guiding principles.",
    },
    {
      key: 2,
      data:
        "Sehwan Development Authority (SDA), an autonomous body functioning under the Ministry of Local Govt. Department, Government of Sindh, was established and mandated to expedite and make provisions for the development and socio-economic improvement and to encourage the housing industry and provide shelter to the people of the Province (Sindh) under (SDA Act, 1993 and Revival - Amending 2013).",
    },
    {
      key: 3,
      data:
        "SDA & KGCP therefore intends to develop a remarkable housing project by the name of Karachi Hills, a world class urban development having all necessary modern-day community living facilities embraces with social mobility and exceptional lifestyle to create a more livable efficient, sustainable, and safe residential yet at an affordable price.",
    },
  ];
  const [active, setActive] = useState(0);

  const [text, setText] = useState(
    "Karachi hills (with SDA) aims to serve the needs of Pakistani community in housing and related services. We strive to be a world-class housing solution provider and innovator with leadership in quality, value for money and management. We put customer, quality, talent and prudence first as the core values that support our guiding principles."
  );
  const change = (value) => {
    if (value === 0) {
      setText(data[0].data);
      setActive(0);
    } else if (value === 1) {
      setText(data[1].data);
      setActive(1);
    } else if (value === 2) {
      setText(data[2].data);
      setActive(2);
    }
  };
  return (
    <>
      <Container data-aos="fade-up" style={{ marginTop: '-40px' }}>
        <Row>
          <Col className="flex flex-wrap justify-center text-center mt-5">
            <h1
              className="myHeading"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
              id='about'
            >
              <span className="text-kgcred">
                {t("about")}
              </span> {t("topHeading")}
            </h1>
          </Col>
        </Row>
        <div
          style={{ marginLeft: "10%", marginRight: "10%" }}
          className="hr-theme-slash-2"
        >
          <div className="hr-line"></div>
          <div className="hr-icon">
            <AiFillInfoCircle className="text-kgcred" size={20} />
          </div>
          <div className="hr-line"></div>
        </div>

        {/* <Row >
          <Col lg="6" md="6" sm="12" xs="12" >
          
          <img src={SideImage} alt="SideImage" style={{ marginLeft: "" }} />
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
          
          <Row>
          <Col lg="12" md="12" sm="12" xs="12">
          <div
          // className="h6"
          style={{ fontSize: '1.3rem' }}
          >
          {t("futKarContent")}
          </div>
          </Col>
          </Row>
          </Col>
          </Row> */}
      </Container>

      <div data-aos="fade-up" style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
        <div className="row-about1 " style={{ border: "" }}>
          <div className="column-about1" style={{ backgroundColor: '' }}>
            <div className="container-set-img-btn1">
              <img src={checkOne} alt="SideImage" className="img2" style={{ marginLeft: "" }} />
                <img src={AboutPotraitOne} alt="SideImage " className="img-zIndex-show "  style={{ marginLeft: "" }} />
            </div>
          </div>
          <div className="column-about1" style={{ backgroundColor: '' }}>
            <div className="about-content" style={{ border: "", width: "50%", margin: "0px auto" }}>
              <div
                className="ab "
                style={{ fontSize: '1rem', border: "" }}
              // className="modern-gated-pad"
              >
                <h3>Why Choose Model Town M9.</h3>
                {t("futKarContent")}
              </div>
              <a href="#contact" style={{}}> <button style={{ marginTop: "20px", paddingLeft: "", marginLeft: "" }} className="button-59" role="button">Contact Us </button></a>
            </div>
          </div>
        </div>
      </div>

      {/* <Container data-aos="fade-up" style={{ marginTop: '20px' }}>
        <Row>
        <Col className="flex flex-wrap justify-center text-center mt-5">
            <h1
              className="myHeading"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
              id='about'
            >
              <span className="text-kgcred">
                {t("about")}
                </span> {t("topHeading")}
                </h1>
                </Col>
                </Row>
                <div
                style={{ marginLeft: "10%", marginRight: "10%" }}
                className="hr-theme-slash-2"
                >
                <div className="hr-line"></div>
                <div className="hr-icon">
                <AiFillInfoCircle className="text-kgcred" size={20} />
                </div>
                <div className="hr-line"></div>
        </div>
        
        <br />
        
        <Row >
          <Col lg="6" md="6" sm="12" xs="12" >
          
          <img src={SideImage} alt="SideImage" style={{ marginLeft: "" }} />
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
          
          <Row>
          <Col lg="12" md="12" sm="12" xs="12">
          <div
          // className="h6"
          style={{ fontSize: '1.3rem' }}
          >
          {t("futKarContent")}
          </div>
          </Col>
          </Row>
          </Col>
          </Row>
        </Container> */}

    </>



  );
};
