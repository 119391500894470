import React, { useState, useEffect } from "react";

import bione from "assets/facilities/cctv.jpg";
import bitwo from "assets/facilities/electricity.jpg";
import bithreee from "assets/facilities/waste.jpg";
import bifour from "assets/facilities/road.jpg";
import bifive from "assets/facilities/natural.jpg";
import bisix from "assets/facilities/modern.jpg";

// import bioneMob from "assets/facilities/";
// import bitwoMob from "assets/facilities/";
// import bithreeeMob from "assets/facilities/";

import { Row, Col, Container } from "reactstrap";

import './styleareeb.css'
import "../views/HomePageSection/amenititiesa.css";
import "../assets/styles/tailwind.css";
import Faqs from "./FAQs";


const BuilderDemo = () => {
    const [focus1, setFocus1] = useState(true)
    const [img1, setImg1] = useState(bione)
    const [content, setContent] = useState({ heading: 'CCTV', contentText: "Hello world 1" })
    const [foo, setFoo] = useState('keyframe-animate1')
    const [fooId, setFooId] = useState('example1')
    console.log(content)
    
    useEffect(() => {
        if (img1 == bione) {
            setFocus1(true)
            setFoo('keyframe-animate1')
            setFooId('example1')
            setContent({ heading: 'CCTV', contentText: "Our security teams duties are primarily to secure and protect the residents of society." })
        } else if (img1 == bitwo) {
            setFocus1(false)
            setFoo('keyframe-animate2')
            setFooId('example2')
            setContent({ heading: 'Electricity Power', contentText: "Providing Electricity advantages that can benefit your family and Society Road Networks." })
        } else if (img1 == bithreee) {
            setFocus1(false)
            setFoo('keyframe-animate3')
            setFooId('example3')
            setContent({ heading: 'Waste Management', contentText: "Includes daily routine collection, transportation, processing, and disposal." })
        } else if (img1 == bisix) {
            setFocus1(false)
            setFoo('keyframe-animate2')
            setFooId('example2')
            setContent({ heading: 'Modern Infrastructure', contentText: "Social & Economic Infrastructure provides Schools, Wide-Roads, and Ready-Made Houses." })
        } else if (img1 == bifour) {
            setFocus1(false)
            setFoo('keyframe-animate3')
            setFooId('example3')
            setContent({ heading: 'Wide Road Network', contentText: "A system of interconnected paved carriageways designed to carry buses, cars, and goods vehicles." })
        } else if (img1 == bifive) {
            setFocus1(false)
            setFoo('keyframe-animate1')
            setFooId('example1')
            setContent({ heading: 'Natural Gas', contentText: "Because it burns smoother, it is the most environmentally beneficial fossil fuel." })
        }else{
            setFocus1(true)
        }
    }, [img1])



    return (
        <>
            <div className="computer-show" style={{ display: "flex", border: "", marginRight: '2%', marginLeft: "2%", marginTop: "1%"}} >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "", padding: "", margin: "", border: "" }} >
                    <button onClick={() => setImg1(bione)} style={{ margin: "6px" }} className={focus1 ? "button-59-check" : "button-59"} role="button">CCTV</button>
                    <button onClick={() => setImg1(bitwo)} style={{ margin: "6px" }} className="button-59" role="button">Electricity Power</button>
                    <button onClick={() => setImg1(bithreee)} style={{ margin: "6px" }} className="button-59" role="button">Waste Management</button>
                    <button onClick={() => setImg1(bisix)} style={{ margin: "6px" }} className="button-59" role="button">Modern Infrastructure</button>
                    <button onClick={() => setImg1(bifour)} style={{ margin: "6px" }} className="button-59" role="button">Wide Road Network</button>
                    <button onClick={() => setImg1(bifive)} style={{ margin: "6px" }} className="button-59" role="button">Natural Gas</button>
                </div>
                <div className="img-set-respo" style={{ justifyContent: "center", border: "", margin: '1px auto', width: "75%", border: "" }}><img src={img1} alt="logo" width='100%' />
                    <div
                        className={foo + ' text-kgcred'} id={fooId}
                        style={{
                            zIndex: '9',
                            marginTop: "-150px",
                            color: "#fff",
                        }}>
                        <h5 className="bold " style={{ position: "relative" }}>{content.heading}</h5>
                        <p className="">{content.contentText}</p>
                    </div>
                </div>
            </div>


            {/* mobile show */}
            {/* <div className="mobile-show" style={{ display: "none", border: "" }} >
                <div style={{ display: "", flexDirection: "column", justifyContent: "", padding: "10px", margin: "", border: "",textAlign:"center",flexWrap:"" }} >
                
                    <button onClick={() => setImg1(bitwo)} style={{ margin: "3px" }} className="button-40" role="button">Electricity Power</button>
                    <button onClick={() => setImg1(bithreee)} style={{ margin: "3px" }} className="button-40" role="button">Waste Management</button>
                    <button onClick={() => setImg1(bione)} style={{ margin: "3px" }} className="button-40" role="button">CCTV</button>
                  
                    <button onClick={() => setImg1(bioneMob)} style={{ margin: "3px" }} className="button-40" role="button">Modern Infrastructure</button>
                    <button onClick={() => setImg1(bitwoMob)} style={{ margin: "3px" }} className="button-40" role="button">Wide Road Network</button>
                    <button onClick={() => setImg1(bithreeeMob)} style={{ margin: "5px" }} className="button-40" role="button">Natural Gas</button>
                </div>
                <div className="" style={{ justifyContent: "center", border: "", margin: '', width: "100%", border: "2px solid green",height:'auto' }}><img src={img1} alt="logo" height='' width='100%'  />
                    <div className={foo} id={fooId}
                        style={{
                            zIndex: '9',
                            marginTop: "-120px",
                            color: "#fff"
                        }}>
                        <h5 className="text-kgcred bold">{content.heading}</h5>
                        <p className="bold" style={{fontSize:"0.7rem"}}>{content.contentText}</p>
                    </div>
                </div>
            </div> */}
            <div className="mobile-show">
                <Faqs />
            </div>
            {/* mobile show */}

        </>
    )
}
export default BuilderDemo
