import React from 'react';

import Carousel from 'react-bootstrap/Carousel';
import bione from "assets/imgfolder1/1.1.jpg";
import bitwo from "assets/imgfolder1/day.jpg";
import bithreee from "assets/imgfolder1/night.jpg";

function Carsoul() {
    return (
        <div >
            <Carousel controls={false}>
                <Carousel.Item  interval={1000}>
                    <img
                        className="d-block w-100 " style={{ height: "100vh" }}
                        src={bitwo}
                        alt="First slide"
                    />
                    {/* <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item >
                    <img
                        className="d-block w-100" style={{ height: "100vh" }}
                        src={bithreee}
                        alt="Second slide"
                    />
                    {/* <Carousel.Caption>
                        <h3>Second slide label</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                {/* <Carousel.Item interval={500}>
                    <img
                        className="d-block w-100"style={{height:"100vh"}}
                        src={bithreee}
                        alt="Third slide"
                    />
                    <Carousel.Caption>
                        <h3>Third slide label</h3>
                        <p>
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                        </p>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </div>
    );
}

export default  Carsoul 